<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Withdrawal requests</h5>
    <ProgressBar
      v-if="loading"
      class="progress-bar"
      mode="indeterminate"
    />
    <DataTable
      v-else
      :value="withdrawData"
      data-key="id"
      paginator
      lazy
      :rows="itemsPerPage"
      :total-records="totalItems"
      @page="changePage"
    >
      <Column header="User">
        <template #body="slot">
          <span
            class="clickable"
            @click="goToUser(slot.data?.user?.id)"
          >
            {{ slot.data?.user?.email ?? "-" }}
          </span>
        </template>
      </Column>
      <Column header="Amount" field="amount">
        <template #body="slot">
          <OutputDecimals
            v-if="slot.data?.value"
            :decimals-value="slot.data?.value"
            decimals="2"
          />
          <span v-else>-</span>
        </template>
      </Column>
      <Column header="Token" field="auToken">
        <template #body="slot">
          {{ slot.data?.token?.nameEng ?? "-" }}
        </template>
      </Column>
      <Column header="Status" field="status">
        <template #body="slot">
          {{ getStatus(slot.data.isConfirmed, slot.data.isTaskDone) ?? "-" }}
        </template>
      </Column>
      <Column header="Hold" field="hold" />
      <Column header="Created at" field="createdAt">
        <template #body="slot">
          {{ formatDate(slot.data?.createdAt) ?? "-" }}
        </template>
      </Column>
      <Column class="border-none bg-white">
        <template #body="slot">
          <div class="for-two-btn-container" v-if="!(slot.data.isTaskDone || slot.data.isConfirmed)">
            <div class="absolute flex gap-2">
              <div data-help="Approve">
                <Button
                  class="p-button-rounded p-button-success"
                  icon="pi pi-check"
                  @click="confirm(slot.data.id)"
                />
              </div>
              <div data-help="Cancel">
                <Button
                  class="p-button-rounded p-button-danger"
                  icon="pi pi-times"
                  @click="decline(slot.data.id)"
                />
              </div>
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import _ from "lodash";
import OutputDecimals from "@/components/edit/OutputDecimals"
import moment from "moment";
export default {
  name: "ManagerWithdraw",
  components: { OutputDecimals },
  data() {
    return {
      loading: false,
      page: 1,
      itemsPerPage: 30,
      withdrawData: [],
      totalItems: 0
    };
  },
  async mounted() {
    await this.getWithdrawData();
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format("DD MMM YYYY hh:mm:ss") : "-";
    },

    goToUser(id) {
      this.$router.push({ name: "MarketingUsers", params: { id: id }});
    },

    async changePage(event) {
      this.page = event.page + 1;
      await this.getWithdrawData()
    },

    getStatus(status, isTaskDone) {
      return !isTaskDone ? (status ? "Confirmed" : "Not confirmed") : "Task completed";
    },

    async getWithdrawData() {
      this.loading = true;
      const headers = {
        accept: "application/ld+json",
        "Content-Type": "application/ld+json",
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        type: "token_withdrawal"
      }
      try {
        const resp = await this.axios.get("/api/token_events", { params, headers });
        this.withdrawData = resp.data["hydra:member"];
        this.totalItems = resp.data["hydra:totalItems"];
        _.forEach(this.withdrawData, async el => el.hold = await this.getHoldStatus(el.id));
      }
      catch (e) {
        this.showError("Error when receiving a list of withdrawal requests");
      }
      this.loading = false;
    },

    async getHoldStatus(parentTaskId) {
      const headers = {
        accept: "application/json",
        "Content-Type": "application/json"
      };
      const params = {
        parentTaskId: parentTaskId,
        type: "token_transfer"
      }
      try {
        const resp = await this.axios.get("/api/token_events", { params, headers });
        const isError = Array.isArray(resp.data) ? resp.data.at(0)?.isError : true;
        return isError ? "Error" : "Hold";
      }
      catch (e) {
        this.showError("Error when receiving a list of withdrawal requests");
      }
    },

    showError(message) {
      this.$toast.add({
        severity: "error",
        summary: "Error",
        detail: message,
        group: "info",
        life: 3000,
      })
    },

    showMessage(message) {
      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: message,
        group: "info",
        life: 3000,
      })
    },

    async decline(id) {
      const params = {
        user: `/api/users/${this.$auth.user().id}`,
        createdAt: new Date,
        type: "token_withdrawal_cancel",
        description: "withdraw",
        task: `/api/token_events/${id}`,
        isTask: true
      };
      try {
        await this.axios.post("/api/token_events", params);
        //await this.axios.delete(`/api/token_events/${id}`);
        await this.getWithdrawData();
        this.showMessage("Payment cancellation completed successfully");
      }
      catch (e) {
        this.showError("Error - " + e.response.data.message);
      }
    },

    async confirm(id) {
      const headers = {
        "Content-Type": "application/merge-patch+json"
      };
      const params = {
        isConfirmed: true
      }
      try {
        await this.axios.patch(`/api/token_events/${id}`, params, { headers });
        await this.getWithdrawData();
        this.showMessage("Payment successfully confirmed");
      }
      catch (e) {
        this.showError("Error - " + e.response.data.message);
      }
    }
  }
}
</script>

<style scoped>

[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: -15px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
</style>